import { app } from 'o365-modules';
import { getDataObjectById, dataObjectStore, type DataObject } from 'o365-dataobject';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type App from 'o365.pwa.modules.client.dexie.objectStores.App.ts';
import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';
import type { Ref } from 'vue';

type IndexedDBApps = Map<string, { value: App, databases: IndexedDBDatabases }>;
type IndexedDBDatabases = Map<string, { value: Database, objectStores: IndexedDBObjectStores }>;
type IndexedDBObjectStores = Map<string, { value: ObjectStore, indexes: IndexedDBIndexes }>;
type IndexedDBIndexes = Map<string, { value: Index, remove: boolean }>;

import 'o365.dataObject.extension.Offline.ts';

dataObjectStore as Map<string, Map<string, Ref<DataObject>>>;

export namespace DataObjectOfflineInitializer {
    export async function initializeOfflineDataObjects(_dataObjectConfigs: Map<string, any>): Promise<void> {
        const indexedDbApps: IndexedDBApps = new Map();

        const dataObjectConfigs = Array.from((_dataObjectConfigs).entries());
        const movedDataObjects = new Set<string>();
        const subConfigToDataObject = new Map<string, string>();
        const masterDetailMapping = new Map<string, string>();

        let i = 0;

        while (i < dataObjectConfigs.length) {
            const [dataObjectId, dataObjectConfig] = dataObjectConfigs[i];

            if (!app.dataObjectConfigs.has(dataObjectId)) {
                app.dataObjectConfigs.set(dataObjectId, dataObjectConfig);
            }

            let moveToEnd = false;

            if (dataObjectConfig.offline?.subConfigs) {
                for (const subConfigKey of Object.keys(dataObjectConfig.offline.subConfigs)) {
                    const subConfig = dataObjectConfig.offline.subConfigs[subConfigKey];
                    const dataObjectSubConfigId = `${dataObjectId}_${subConfigKey}`;

                    if (!subConfigToDataObject.has(dataObjectSubConfigId)) {
                        subConfigToDataObject.set(dataObjectSubConfigId, dataObjectId);
                    }

                    if (!masterDetailMapping.has(dataObjectSubConfigId)) {
                        masterDetailMapping.set(dataObjectSubConfigId, subConfig.masterDataObject_ID);
                    }

                    if (subConfig.masterDataObject_ID) {
                        const masterDataObject = dataObjectStore.get(app.id)!.get(subConfig.masterDataObject_ID)?.value;

                        if (!masterDataObject) {

                            if (masterDetailMapping.has(dataObjectSubConfigId)) {
                                let key: string | undefined = dataObjectSubConfigId;

                                do {
                                    key = masterDetailMapping.get(key);

                                    if (key && subConfigToDataObject.has(key) && subConfigToDataObject.get(key) === dataObjectId) {
                                        throw new Error(`Failed to configure data object (${dataObjectSubConfigId}). One of the nested MasterDetail DataObjects has the same original DataObject (${dataObjectId})`);
                                    }
                                } while (key && key !== dataObjectSubConfigId);

                                if (key === dataObjectSubConfigId) {
                                    throw new Error(`Failed to configure data object (${dataObjectSubConfigId}). The nested MasterDetail DataObjects ends up back at the same DataObject`);
                                }
                            }

                            dataObjectConfigs.push(dataObjectConfigs.splice(i, 1)[0]);

                            moveToEnd = true;
                            break;
                        }
                    }
                }
            }

            if (moveToEnd) {
                if (!movedDataObjects.has(dataObjectId)) {
                    movedDataObjects.add(dataObjectId);
                }

                continue;
            }

            if (movedDataObjects.has(dataObjectId)) {
                movedDataObjects.add(dataObjectId);
            }

            const dataObject: DataObject = getDataObjectById(dataObjectId, app.id);

            if (dataObject === undefined) {
                debugger;
            }

            if (dataObject.shouldEnableOffline === false) {
                i++;
                continue;
            }

            dataObject.enableOffline();

            const syncDataObjectId = dataObjectId + '_sync';
            const syncConfig = Object.assign({}, dataObjectConfig, { id: syncDataObjectId, appId: app.id });

            app.dataObjectConfigs.set(syncDataObjectId, syncConfig);

            const syncObject = getDataObjectById(syncDataObjectId, app.id);

            syncObject.enableOffline();

            if (dataObjectConfig.offline.subConfigs) {
                for (const subConfig of Object.keys(dataObjectConfig.offline.subConfigs)) {
                    const dataObjectSubConfigId = `${dataObjectId}_${subConfig}`;
                    const dataObjectSubConfig = deepMerge({}, dataObjectConfig, dataObjectConfig.offline.subConfigs[subConfig], { id: dataObjectSubConfigId, appId: app.id });

                    app.dataObjectConfigs.set(dataObjectSubConfigId, dataObjectSubConfig);

                    const subDataObject = getDataObjectById(dataObjectSubConfigId, app.id);

                    subDataObject.enableOffline();
                }
            }

            const appRecordId = dataObject.offline.appIdOverride ?? app.id;

            let idbAppCacheEntry = indexedDbApps.get(appRecordId);

            if (idbAppCacheEntry === undefined) {
                let appRecord = await IndexedDBHandler.getApp(appRecordId);

                if (appRecord === null) {
                    appRecord = await IndexedDBHandler.createApp(appRecordId);
                }

                idbAppCacheEntry = {
                    value: appRecord,
                    databases: new Map()
                };

                indexedDbApps.set(appRecordId, idbAppCacheEntry);
            }

            const databaseRecordId = dataObject.offline.databaseIdOverride ?? 'DEFAULT';

            let idbDatabaseCacheEntry = idbAppCacheEntry.databases.get(databaseRecordId);

            if (idbDatabaseCacheEntry === undefined) {
                let databaseRecord = await idbAppCacheEntry.value.databases[databaseRecordId];

                if (databaseRecord === null) {
                    databaseRecord = await IndexedDBHandler.createDatabase(appRecordId, databaseRecordId);
                }

                idbDatabaseCacheEntry = {
                    value: databaseRecord,
                    objectStores: new Map()
                };
            }

            const objectStoreRecordId = dataObject.offline.objectStoreIdOverride ?? dataObject.id;

            let idbObjectStoreCacheEntry = idbDatabaseCacheEntry.objectStores.get(objectStoreRecordId);

            const jsonDataVersion = dataObject.offline.jsonDataVersion;
            const fields: Array<string> = (() => {
                try {
                    return dataObjectConfig.fields.map((field: any) => field.name);
                } catch (reason) {
                    console.error(reason);

                    return new Array();
                }
            })();

            if (idbObjectStoreCacheEntry === undefined) {
                let objectStoreRecord = await idbDatabaseCacheEntry.value.objectStores[objectStoreRecordId];

                if (objectStoreRecord === null) {
                    const initializeDataObject = dataObjectConfig.offline.initializeDataObject === true;

                    objectStoreRecord = await IndexedDBHandler.createObjectStore(
                        appRecordId,
                        databaseRecordId,
                        objectStoreRecordId,
                        jsonDataVersion,
                        fields,
                        false,
                        initializeDataObject ? dataObjectConfig : undefined,
                        initializeDataObject
                    );
                } else if (
                    // TODO: Update DataObjectConfig if initializeDataObject === true and config has changed

                    objectStoreRecord.jsonDataVersion !== dataObject.offline.jsonDataVersion ||
                    objectStoreRecord.fields?.length !== fields.length ||
                    new Set([...objectStoreRecord.fields, ...fields]).size !== (new Set(fields)).size
                ) {
                    objectStoreRecord.jsonDataVersion = dataObject.offline.jsonDataVersion;
                    objectStoreRecord.fields = fields;

                    await objectStoreRecord.save();
                }

                idbObjectStoreCacheEntry = {
                    value: objectStoreRecord,
                    indexes: new Map()
                };
            }

            const indexConfigs = dataObject.offline.indexedDBIndexes;

            const indexRecords = await idbObjectStoreCacheEntry.value.indexes.getAll();

            for (const indexRecord of indexRecords) {
                idbObjectStoreCacheEntry.indexes.set(indexRecord.id, {
                    value: indexRecord,
                    remove: true
                });
            }

            for (const indexConfig of indexConfigs) {
                const indexRecordId = indexConfig.id;

                let idbIndexCacheEntry = idbObjectStoreCacheEntry.indexes.get(indexRecordId);

                if (idbIndexCacheEntry === undefined) {
                    let indexRecord = await idbObjectStoreCacheEntry.value.indexes[indexRecordId];

                    if (indexRecord === null) {
                        indexRecord = await IndexedDBHandler.createIndex(
                            appRecordId,
                            databaseRecordId,
                            objectStoreRecordId,
                            indexRecordId,
                            indexConfig.keyPath,
                            indexConfig.isPrimaryKey,
                            indexConfig.isUnique,
                            indexConfig.isMultiEntry,
                            indexConfig.isAutoIncrement
                        );
                    } else if (
                        indexRecord.keyPath !== indexConfig.keyPath ||
                        indexRecord.isPrimaryKey !== indexConfig.isPrimaryKey ||
                        indexRecord.isUnique !== indexConfig.isUnique ||
                        indexRecord.isMultiEntry !== indexConfig.isMultiEntry ||
                        indexRecord.isAutoIncrement !== indexConfig.isAutoIncrement
                    ) {
                        indexRecord.keyPath = indexConfig.keyPath;
                        indexRecord.isPrimaryKey = indexConfig.isPrimaryKey;
                        indexRecord.isUnique = indexConfig.isUnique;
                        indexRecord.isMultiEntry = indexConfig.isMultiEntry;
                        indexRecord.isAutoIncrement = indexConfig.isAutoIncrement;

                        await indexRecord.save();
                    }

                    idbIndexCacheEntry = {
                        value: indexRecord,
                        remove: false
                    }
                } else if (
                    idbIndexCacheEntry.value.keyPath !== indexConfig.keyPath ||
                    idbIndexCacheEntry.value.isPrimaryKey !== indexConfig.isPrimaryKey ||
                    idbIndexCacheEntry.value.isUnique !== indexConfig.isUnique ||
                    idbIndexCacheEntry.value.isMultiEntry !== indexConfig.isMultiEntry ||
                    idbIndexCacheEntry.value.isAutoIncrement !== indexConfig.isAutoIncrement
                ) {
                    idbIndexCacheEntry.value.keyPath = indexConfig.keyPath;
                    idbIndexCacheEntry.value.isPrimaryKey = indexConfig.isPrimaryKey;
                    idbIndexCacheEntry.value.isUnique = indexConfig.isUnique;
                    idbIndexCacheEntry.value.isMultiEntry = indexConfig.isMultiEntry;
                    idbIndexCacheEntry.value.isAutoIncrement = indexConfig.isAutoIncrement;

                    await idbIndexCacheEntry.value.save();

                    idbIndexCacheEntry.remove = false;
                } else {
                    idbIndexCacheEntry.remove = false;
                }
            }

            for (let [_, idbIndexCacheEntry] of idbObjectStoreCacheEntry.indexes.entries()) {
                if (idbIndexCacheEntry.remove) {
                    await idbIndexCacheEntry.value.delete();
                }
            }

            i++;
        }

        for (let [_, idbAppCacheEntry] of indexedDbApps) {
            await idbAppCacheEntry.value.initialize();
        }
    }

    export async function initializeCustomDataObjects(): Promise<void> {
        const appRecord = await IndexedDBHandler.getApp(app.id);

        if (appRecord === null) {
            console.error('Failed to find app record');
            return;
        }

        const databaseRecords = await appRecord.databases.getAll();

        for (let databaseRecord of databaseRecords) {
            const objectStoreRecords = await databaseRecord.objectStores.getAll();

            for (let objectStoreRecord of objectStoreRecords) {
                if (objectStoreRecord.initializeDataObject && objectStoreRecord.dataObjectConfig && objectStoreRecord.dataObjectConfig) {
                    const dataObjectConfig = objectStoreRecord.dataObjectConfig;

                    app.dataObjectConfigs.set(dataObjectConfig.id, dataObjectConfig);

                    const dataObject = getDataObjectById(dataObjectConfig.id, app.id);

                    dataObject.enableOffline();
                }
            }
        }
    }
}

function deepMerge(target: any, ...sources: any) {
    if (!target || typeof target !== 'object') {
        throw new Error("Target must be an object");
    }

    sources.forEach(source => {
        if (!source || typeof source !== 'object') {
            return;
        }

        Object.keys(source).forEach(key => {
            const targetValue = target[key];
            const sourceValue = source[key];

            if (
                typeof sourceValue === 'object' &&
                sourceValue !== null &&
                !Array.isArray(sourceValue)
            ) {
                target[key] = deepMerge(
                    targetValue && typeof targetValue === 'object' ? targetValue : {},
                    sourceValue
                );
            } else {
                target[key] = sourceValue;
            }
        });
    });

    return target;
}

export default DataObjectOfflineInitializer;
